import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import BulkSMSContact from "./BulkSMSContact";
import BulkSMSFile from "./BulkSMSFile";
import BulkSMSHeader from "./BulkSMSHeader";

class BulkSMSTo extends Component {
  render() {
    return (
      <Fragment>
        <section className="bulksms__to">
          <BulkSMSHeader
            mode={this.props.mode}
            setModeNormal={this.props.setModeNormal}
            setModeFile={this.props.setModeFile}
            contacts={this.props.contacts}
          />
          {this.props.mode === "normal" ? (
            <BulkSMSContact
              handleAddContact={this.props.handleAddContact}
              handleChange={this.props.handleChange}
              contact={this.props.contact}
              contacts={this.props.contacts}
              message={this.props.message}
              hasErr={this.props.hasErr}
              handleClick={this.props.removeNumber}
            />
          ) : null}
          {this.props.mode === "file" ? (
            <BulkSMSFile
              hasErr={this.props.hasErr}
              handleChangeFile={this.props.handleChangeFile}
              file={this.props.file}
              errMessage={this.props.fileErrMessage}
              extract={this.props.extract}
              extracting={this.props.extracting}
              contacts={this.props.contacts}
              handleClick={this.props.removeNumber}
            />
          ) : null}

             {this.props.contacts.length > 0 ? (
              <div className="bulksms__clear-btn-wrapper">
                <button
                  className="bulksms__clear-btn"
                  onClick={this.props.clearContacts}
                >
                  Clear all contacts
                </button>
              </div>
            ) : null}
        </section>
      </Fragment>
    );
  }
}

export default withRouter(BulkSMSTo);
