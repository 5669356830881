import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import Spinner from "./Spinner";

class BulkSMSFile extends Component {
  render() {
    return (
      <Fragment>
        <div className="bulksms__to-file-contact">
          <Fragment>
            {this.props.contacts.length > 0 ? (
              <div className="bulksms__to-file-contact-container">
                {this.props.contacts.map((contact, i) => {
                  return (
                    <p
                      key={i + 1}
                      className="bulksms__to-file-contact-number"
                      style={{
                        fontSize: "1.2rem",
                        color: "#212121",
                        fontWeight: 400,
                      }}
                    >
                      {contact}
                      <i
                        id={`${contact}`}
                        onClick={this.props.handleClick}
                        className="icofont-delete"
                      ></i>
                    </p>
                  );
                })}
              </div>
            ) : null}
          </Fragment>

          {!this.props.extracting ? (
            <Fragment>
              {this.props.file ? (
                <div className="bulksms__to-file-contact-info">
                  <i className="icofont-file-document"></i>
                  <p>File : {this.props.file.name}</p>
                  <p>Size : {(this.props.file.size / 1024).toFixed(2)} KB</p>
                  <button onClick={this.props.extract}>Extract</button>
                </div>
              ) : (
                <Fragment>
                  {this.props.contacts.length > 0 ? null : (
                    <Fragment>
                      <p>Choose an excel file to upload</p>
                      <form className="bulksms__to-file-form">
                        <label htmlFor="file">
                          <p>
                            <i className="icofont-upload-alt"></i>
                            Browse
                          </p>
                        </label>
                        <input
                          type="file"
                          name="file"
                          id="file"
                          onChange={this.props.handleChangeFile}
                        />
                      </form>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          ) : null}

          {this.props.extracting ? (
            <Fragment>
              <Spinner />
              <p className="bulksms__to-contact-message">
                Extracting contacts...
              </p>
            </Fragment>
          ) : null}
          <p
            className="bulksms__to-contact-message"
            style={
              this.props.hasErr ? { color: "#fa392b" } : { color: "#05c568" }
            }
          >
            {this.props.errMessage}
          </p>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(BulkSMSFile);
