import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";

class BulkSMSMessage extends Component {
  render() {
    return (
      <Fragment>
        <div className="bulksms__message">
          <p className="bulksms__message-heading">The text of your message</p>
          <div className="bulksms__message-container">
            <textarea
              name="message"
              placeholder="Type your message here"
              value={this.props.message}
              onChange={this.props.handleChange}
            ></textarea>
          </div>
          <p
            className="bulksms__message-heading"
            style={
              this.props.hasErr
                ? { color: "#cf7703", fontSize: "12px", fontWeight: "400" }
                : { color: "#212121", fontSize: "12px", fontWeight: "400" }
            }
          >
            {this.props.errMessage}
          </p>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(BulkSMSMessage);
