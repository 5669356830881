import React, { Component } from "react";
import { withRouter } from "react-router";

class BulkSMSSenderId extends Component {
  render() {
    return (
      <div className="bulksms__senderid">
        <p className="bulksms__senderid-heading">Sender ID</p>
        <input
          name="senderId"
          placeholder="Sender ID"
          type="tel"
          value={this.props.senderId}
          onChange={this.props.handleChange}
        />
      
      </div>
    );
  }
}

export default withRouter(BulkSMSSenderId);
