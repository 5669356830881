import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

class Navigation extends Component {
  render() {
    return (
      <nav className="navigation">
        <Link to={"/"}>
          <p className="navigation__logo-tagline">BULK SMS SENDER</p>
          <i className="icofont-ui-messaging"></i>
        </Link>
      </nav>
    );
  }
}

export default withRouter(Navigation);
