import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";

class BulkSMSContact extends Component {
  componentDidMount() {
    this.nameInput.focus();
  }
  render() {
    return (
      <Fragment>
        <div className="bulksms__to-contact-container">
          <div className="bulksms__to-contact">
            <Fragment>
              {this.props.contacts.map((contact, i) => {
                return (
                  <p key={i + 1} className="bulksms__to-contact-number">
                    {contact}
                    <i
                      onClick={this.props.handleClick}
                      id={`${contact}`}
                      className="icofont-delete"
                    ></i>
                  </p>
                );
              })}
            </Fragment>
            <form
              className="bulksms__to-contact-form"
              onSubmit={this.props.handleAddContact}
            >
              <input
                type="tel"
                name="contact"
                placeholder="number"
                onChange={this.props.handleChange}
                value={this.props.contact}
                ref={(input) => {
                  this.nameInput = input;
                }}
              />
              <button>Add</button>
            </form>
          </div>
        </div>

        <p
          className="bulksms__to-contact-message"
          style={
            this.props.hasErr ? { color: "#fa392b" } : { color: "#05c568" }
          }
        >
          {this.props.message}
        </p>
      </Fragment>
    );
  }
}

export default withRouter(BulkSMSContact);
