import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import Spinner from "./Spinner";

class BulkSMSPopup extends Component {
  render() {
    return (
      <section className="bulksms__popup">
        <div className="bulksms__popup-message-box">
          {this.props.loading ? (
            <Spinner />
          ) : (
            <Fragment>
              {this.props.messageErr ? null : (
                <i className="icofont-ui-messaging"></i>
              )}
            </Fragment>
          )}
          {this.props.messageErr ? (
            <i
              className="icofont-exclamation-tringle"
              style={{ color: "#fa392b" }}
            ></i>
          ) : null}
          <p
            style={
              this.props.sendingErr
                ? { color: "#fa392b" }
                : { color: "#212121" }
            }
          >
            {this.props.sendingMessage}
          </p>
          {this.props.loading ? null : (
            <button onClick={this.props.handleClick}>Close</button>
          )}
        </div>
      </section>
    );
  }
}

export default withRouter(BulkSMSPopup);
