import React, { Component, Fragment } from "react"
import { withRouter } from "react-router"
import XLXS from "read-excel-file"
import BulkSMSTo from "./BulkSMSTo"
import BulkSMSMessage from "./BulkSMSMessage"
import BulkSMSSenderId from "./BulkSMSSenderId"
import BulkSMSPopup from "./BulkSMSPopup"

class BulkSMS extends Component {
	state = {
		open: false,
		sending: false,
		mode: "normal",
		hasErr: false,
		errMessage: "",
		messageWarning: "",
		messageErr: false,
		senderErrMessage: "",
		sendErrMessage: "",
		sendingMessage: "",
		sendingErr: false,
		loading: false,
		file: null,
		fileErrMessage: "",
		extracting: false,
		contact: "",
		message: "",
		contacts: [],
		senderId: "",
	}

	closePopup = () => {
		this.setState({ open: false, messageErr: false })
		if (!this.state.messageErr) {
			this.setState({
				open: false,
				contacts: [],
				contact: "",
				file: "",
				senderId: "",
				message: "",
			})
		}
	}

	sendMessage = () => {
		const { contacts, senderId, message } = this.state

		if (contacts.length === 0) {
			this.setState({
				sendErrMessage: "No contacts to send message to!",
				hasErr: true,
			})
			return
		}

		if (!message || typeof message !== "string") {
			this.setState({
				sendErrMessage: "Please write valid message with no special characters",
				hasErr: true,
			})
			return
		}

		if (message.length > 160) {
			this.setState({
				sendErrMessage: "Message cannot be more than 160 charactaers!",
				hasErr: true,
			})
			return
		}
		if (!this.state.senderId) {
			this.setState({
				sendErrMessage: "Please Enter SenderID",
				hasErr: true,
			})
			return
		}

		if (contacts.length > 5000) {
			this.setState({
				sendErrMessage: "Contacts has exceeded 10,000 limits!",
				hasErr: true,
			})
			return
		}

		this.setState({
			hasErr: false,
			sendErrMessage: "",
		})
		const data = {
			contacts,
			senderId,
			message,
		}

		this.setState({
			sending: true,
			loading: true,
			open: true,
			sendingMessage: "Sending message...",
		})

		fetch("/message", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then(response => response.json())
			.then(data => {
				console.log(data)
				this.setState({
					sending: false,
					loading: false,
					messageErr: data.status === "success" ? false : true,
					sendingMessage: data.message,
				})
			})
			.catch(err => {
				this.setState({
					loading: false,
					sending: false,
					messageErr: true,
					sendingMessage: "Error occured while sending message",
				})
			})
	}

	handleChangeFile = e => {
		this.setState({ file: e.target.files[0] })
	}

	extract = () => {
		this.setState({ sendErrMessage: "" })
		const { file } = this.state
		const fileExtension = file.name.split(".")
		const fileExtensionName = fileExtension[fileExtension.length - 1].toLowerCase()

		if (!file) {
			this.setState({ fileErrMessage: "Please upload a file", hasErr: true })
			return
		}

		this.setState({ fileErrMessage: "", hasErr: false })

		if (!["xlsx"].includes(fileExtensionName)) {
			this.setState({
				fileErrMessage: "Upload a valid excel file! .xlsx",
				hasErr: true,
				file: null,
			})
			setTimeout(() => {
				this.setState({ fileErrMessage: "", hasErr: false })
			}, 3000)
			return
		}

		let numbers = []
		this.setState({ extracting: true })
		XLXS(file)
			.then(rows => {
				for (var i = 0; i < rows.length; i++) {
					for (var j = 0; j < rows[i].length; j++) {
						let number = rows[i][j]
						numbers.push(number)
					}
				}

				numbers = numbers.map(num => (`${num}`.startsWith("+") ? num : `+${num}`))
				numbers = numbers.filter(num => /^\+[1-9]\d{1,14}$/g.test(num))
				let fileErrMessage = ""
				let hasErr = false

				if (numbers.length === 0) {
					hasErr = true
					fileErrMessage = "No contacts found!"
				}

				if (numbers.length > 5000) {
					fileErrMessage = "Contacts has exeeded 10,000 limits"
					hasErr = false
				}

				this.setState({
					extracting: false,
					fileErrMessage,
					file: null,
					contacts: [...numbers],
					hasErr,
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({
					hasErr: true,
					errMessage: "Error extracting file!",
				})
			})
	}

	removeNumber = e => {
		let { contacts } = this.state
		contacts = contacts.filter(id => id !== e.target.id)
		this.setState({ contacts: [...contacts] })
	}

	setModeFile = () => this.setState({ mode: "file", contacts: [], contact: "" })
	setModeNormal = () => this.setState({ mode: "normal", contacts: [], contact: "" })
	handleChange = e => {
		e.preventDefault()
		this.setState({
			errMessage: "",
			sendErrMessage: "",
			sendMessage: "",
			...this.state,
			[e.target.name]: e.target.value,
		})

		if (e.target.name === "message") {
			if (e.target.value.split("").length > 160) {
				this.setState({
					hasErr: true,
					messageWarning: "Message exceeded 160 characters!",
				})
			} else {
				this.setState({ messageWarning: "" })
			}
		}
	}

	handleAddContact = e => {
		e.preventDefault()
		const { contact, contacts } = this.state
		if (!contact) {
			this.setState({
				hasErr: true,
				errMessage: "Enter a valid number!",
			})
			return
		}
		if (!/^\+[1-9]\d{1,14}$/g.test(contact)) {
			this.setState({
				hasErr: true,
				errMessage: "Enter a valid number in E.164 format eg +33609876548!",
			})
			return
		}

		if (`${contact}`.length < 10) {
			this.setState({
				hasErr: true,
				errMessage: "Enter a valid number eg 19345876765!",
			})
			return
		}

		if (contacts.includes(contact)) {
			this.setState({
				errMessage: "Contact already added!",
				hasErr: true,
			})
			return
		}

		contacts.push(contact)

		this.setState({
			errMessage: "",
			contacts: [...contacts],
			contact: "",
			sendMessage: "",
		})
	}

	clearContacts = () => {
		this.setState({ contacts: [] })
	}

	render() {
		return (
			<Fragment>
				<main className="bulksms">
					<div className="bulksms__container">
						<BulkSMSTo
							setModeNormal={this.setModeNormal}
							setModeFile={this.setModeFile}
							mode={this.state.mode}
							handleAddContact={this.handleAddContact}
							contact={this.state.contact}
							handleChange={this.handleChange}
							contacts={this.state.contacts}
							message={this.state.errMessage}
							hasErr={this.state.hasErr}
							handleChangeFile={this.handleChangeFile}
							file={this.state.file}
							fileErrMessage={this.state.fileErrMessage}
							extract={this.extract}
							extracting={this.state.extracting}
							removeNumber={this.removeNumber}
							clearContacts={this.clearContacts}
						/>

						<BulkSMSMessage
							handleChange={this.handleChange}
							message={this.state.message}
							errMessage={this.state.messageWarning}
							hasErr={this.state.hasErr}
						/>
						<div className="bulksms__to-total-contact-wrapper">
							<p>
								Total Contacts :<span>{this.state.contacts.length}</span>
							</p>
						</div>
						<div className="bulksms__senderid-wrapper">
							<BulkSMSSenderId
								hasErr={this.state.hasErr}
								handleChange={this.handleChange}
								senderId={this.state.senderId}
								senderErrMessage={this.state.senderErrMessage}
							/>
							<button
								className="bulksms__sendmessage-button"
								onClick={this.sendMessage}
							>
								SEND MESSAGE
							</button>
						</div>
						<p
							className="bulksms__to-contact-message"
							style={this.state.hasErr ? { color: "#fa392b" } : { color: "#05c568" }}
						>
							{this.state.sendErrMessage}
						</p>
					</div>
				</main>
				{this.state.open ? (
					<BulkSMSPopup
						sendingMessage={this.state.sendingMessage}
						sendingErr={this.state.sendingErr}
						handleClick={this.closePopup}
						loading={this.state.loading}
						messageErr={this.state.messageErr}
					/>
				) : null}
			</Fragment>
		)
	}
}

export default withRouter(BulkSMS)
