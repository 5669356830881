import React, { Component, Fragment } from "react";
import { BrowserRouter } from "react-router-dom";
import BulkSMS from "./components/BulkSMS";
import Layout from "./components/Layout";
import Navigation from "./components/Navigation";

class App extends Component {
  render() {
    return (
      <Fragment>
        <BrowserRouter>
          <Layout>
            <Navigation />
            <BulkSMS />
          </Layout>
        </BrowserRouter>
      </Fragment>
    );
  }
}

export default App;
