import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";

class BulkSMSHeader extends Component {
  render() {
    let classesOne = ["bulksms__to-navigation-item"];
    let classesTwo = ["bulksms__to-navigation-item"];
    if (this.props.mode === "normal")
      classesOne.push("bulksms__to-navigation-item-active");
    if (this.props.mode === "file")
      classesTwo.push("bulksms__to-navigation-item-active");

    return (
      <Fragment>
        <div className="bulksms__to-heading-wrapper">
          <p className="bulksms__to-heading">Send campaign SMS</p>
        
        </div>

        <div className="bulksms__to-navigation">
          <div
            className={classesOne.join(" ")}
            onClick={this.props.setModeNormal}
          >
            <i className="icofont-pen-alt-2"></i>
            <p>Enter Number</p>
          </div>
          <div
            className={classesTwo.join(" ")}
            onClick={this.props.setModeFile}
          >
            <i className="icofont-upload-alt"></i>
            <p>Number from file</p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(BulkSMSHeader);
